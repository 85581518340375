import React from 'react';
import logo from '../assets/Asset 21 1.png'

const NavBar = () => {
  return (
    <div className='w-full flex md:justify-center justify-between items-center md:px-48 p-4'>

        <div className='md:text-center'>
        <img className='w-[104px] h-[23px]' src={logo} alt='logo' />
        </div>
        <div className='md:absolute lg:right-44 md:right-16'>
          {/* <span className=' text-[#1C1C1C] font-bold text-[14px] Poppins-bold'>Log in</span> */}
        </div>
      </div>
  
  );
}

export default NavBar;
