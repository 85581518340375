import React from 'react'
import grp from '../../assets/Group-22.png'
import group from '../../assets/Illustration 2 1.svg'
import elips from '../../assets/Ellipse33.svg'
import curcle from '../../assets/Ellipse 5.svg'
import curcle1 from '../../assets/Ellipse 6.svg'
import rectengle41 from '../../assets/Rectangle 2041.png'
import rectengle from '../../assets/Rectangle 2037.svg'
import ellipse from '../../assets/Ellipse 1.svg'
import ellipse1 from '../../assets/Ellipse 2.svg'
import { LuUser2 } from "react-icons/lu";
import vivek from '../../assets/Untitled design (1) 1.png'
import rectengle44 from '../../assets/Rectangle444.png'
import ScrolllBar from './ScrolllBar'


const Misson = () => {

  return (
    <>
      <div className='md:px-24 flex flex-col justify-center items-center'>
        <div className=' leading-[2.9rem]'>
          <h1 className='main-heading-t'>Our  <span className='main-heading-S'>Mission</span></h1>
          <p className='main-subheadings-t'>Transforming Learning Experiences</p>
          <p className='main-p'>
            At Colabrary, our mission is to break down educational barriers by creating a dynamic platform where collaboration, innovation, and interactive learning thrive. We believe that education should be a shared journey, empowering everyone to learn, grow, and excel.
          </p>
          <div className="flex justify-center mt-6 md:p-0 p-2 ">
            <img src={grp} alt="grp" className="lg:w-[90%] sm:h-96 z-50" />
          </div>

        </div>

      </div>

      <div className=' bg-[#E7CB4C] sm:h-[500px] h-[558px] mt-12 relative '>
        <div className=''>
          <img src={elips} alt='elips' className='h-[542px] w-[61rem]  res-hidden absolute top-[-181px] left-[-200px]' />
          <div>
            <img src={group} alt='group' className='h-[543px] w-[654px] res-hidden absolute top-[-40px]' />
          </div>
        </div>
        <div className="text-black absolute top-0 right-0 p-8 md:pr-[9rem]">
          <h1 className="text-[35px] Poppins-meadiam leading-[2.2rem] text-center">
            Why Choose <br />
            <span className="Poppins-bold">Colabrary?</span>
          </h1>
          <div className="divboxMis Poppins-bold">
            <span className="shrink">01</span>
            <div className="divr"></div>
            <span >Improved Learning Outcomes</span>
          </div>
          <div className="divboxMis Poppins-bold">
            <span className="shrink">02</span>
            <div className="divr"></div>
            <span>Accessible Education</span>
          </div>
          <div className="divboxMis Poppins-bold">
            <span className="shrink">03</span>
            <div className="divr"></div>
            <span>Empowering Educators</span>
          </div>
          <div className="divboxMis Poppins-bold">
            <span className="shrink">04</span>
            <div className="divr"></div>
            <span>Building a Stronger
              Educational Community</span>
          </div>


        </div>

      </div>
      <div className=' relative'>
        {/* <img src={curcle} alt='curcle' className=' res-hidden absolute top-[-562px;]' /> */}
        {/* <img src={curcle1} alt='curcle' className=' absolute top-0  right-0 h-[133vh] w-[512px] ' /> */}
      </div>
      <h1 className='main-heading-t mt-4'>What's  <span className='main-heading-S'>Next</span></h1>
      <p className='main-subheadings-t'>Our Roadmap</p>

  <ScrolllBar />


      <div>
        <h1 className='main-heading-t '>Colabrary's&nbsp;<span className='main-heading-S'>Vission</span></h1>
        <p className='main-subheadings-t '>Empowering Learners Everywhere</p>
        <div className="relative w-full mt-4">
          <img src={ellipse} alt="ellipse" className="left-0 top-[123px] md:auto res-hidden-md h-24 w-20 absolute z-20" />
          <img src={ellipse1} alt="ellipse1" className="right-0 md:w-auto w-8 h-24 res-hidden-md top-[-24px] absolute z-20" />
          <img src={rectengle} alt="rectengle" className="w-full lg:bottom-[-176px] res-hidden-md absolute z-10" />
          <p className='z-50 lg:text-[#FFFFFF] Poppins-light text-[#1C1C1C] absolute lg:top-[60px] xs:top-0 flex-wrap  text-[16px] lg:px-20 xs:px-1 text-center'>
            Vivek Vyas is dedicated to empowering students and educators by providing them with state-of-the-art tools and an environment that fosters growth. His mission is to transform educational experiences through innovative solutions that bridge gaps and create opportunities for all.
          </p>
          <div className='flex  text-center justify-center items-center mb-[15.5rem]'>
            <img src={vivek} alt='vivek' className=' absolute z-50 w-20 md:top-20 sm:top-28 top-36' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Misson