import React from 'react';
import { IoMdArrowRoundForward } from "react-icons/io";
import img from '../../assets/Rectangle444.png';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
    const navigation = useNavigate()

    const handleNavigation = ()=>{
        navigation('/blog-details')
    }

    const blogs = [
        {
            id: 1,
            date: 'May 16, 2024',
            readTime: '10 min read',
            title: 'User Retention: From Measuring to Improving',
            description: `Acquiring a new customer can cost five times more than retaining an existing one. Additionally, increasing customer retention just by 5% can increase profits from 25-95%. In other words, understanding the nuances of customer retention is paramount.
            You can’t get away with just attracting new customers. Your strategic goal is to keep them engaged and loyal. In this article, we will go through the techniques we employ to measure and improve retention for our clients. Let’s dive right in.`,
            imgSrc: img,
            tags: ['Product design', 'Metrics', 'SaaS'],
        }
    ];

    const blog= [
        {
            id: 1,
            date: 'July 8, 2024',
            readTime: '8 min read',
            title: 'UX Metrics: What You Should Measure and Why',
            imgSrc: img,
            tags: ['User Interviews', 'UX Strategy', 'Metrics', 'SaaS'],
        },
        {
            id: 2,
            date: 'July 8, 2024',
            readTime: '8 min read',
            title: 'UX Strategy: From Planning to Results',
            imgSrc: img,
            tags: ['UX Strategy', 'Product design', 'Metrics'],
        },
        {
            id: 3,
            date: 'July 5, 2024',
            readTime: '6 min read',
            title: 'The Guide to UX of Banking Apps',
            imgSrc: img,
            tags: ['UX Strategy', 'Product design'],
        }
    ];

    return (
        <div className="p-6 md:p-12 lg:p-24">
            <h1 className="text-4xl font-bold mb-8">Our news and articles</h1>
            {blogs.map((blog) => (
                <div key={blog.id} className="flex flex-col lg:flex-row items-start lg:items-center mb-16">
                    <div className="lg:w-1/2">
                        <img
                            src={blog.imgSrc}
                            alt={blog.title}
                            className="rounded-lg w-[600px] h-[400px]"  // Fixed size
                        />
                    </div>
                    <div className="lg:w-1/2 lg:pl-10 mt-4 lg:mt-0">
                        <h2 className="text-gray-600">{blog.date} ♦ {blog.readTime}</h2>
                        <h2 className="text-3xl font-bold mt-2">{blog.title}</h2>
                        <p className="mt-4 text-gray-700">{blog.description}</p>
                        <div className="mt-4 space-x-2">
                            {blog.tags.map((tag, index) => (
                                <span key={index} className="py-1 px-2 bg-gray-100 rounded-md text-sm">{tag}</span>
                            ))}
                        </div>
                        <span onClick={handleNavigation} className="pt-4 cursor-pointer text-red-600 font-semibold flex items-center">
                            Read the article <IoMdArrowRoundForward className="ml-2" />
                        </span>
                    </div>
                </div>
            ))}
        
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {blog.map((blog) => (
                    <div key={blog.id} className="flex flex-col">
                        <img
                            src={blog.imgSrc}
                            alt={blog.title}
                            className="rounded-t-lg m-2 w-[90%] h-[300px]" // Fixed size
                        />
                        <div className="p-4">
                            <h1 className="text-gray-600">{blog.date} ♦ {blog.readTime}</h1>
                            <h2 className="text-[22px] font-bold mt-2">{blog.title}</h2>
                            <div className="mt-4 space-x-2">
                                {blog.tags.map((tag, index) => (
                                    <span key={index} className="py-1 px-2 bg-gray-100 rounded-md text-sm">{tag}</span>
                                ))}
                            </div>
                            <span className="pt-4 text-red-600 font-semibold flex items-center">
                                Read the article <IoMdArrowRoundForward className="ml-2" />
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
                {/* First Column */}
                <div className="flex flex-col">
                    <img
                        src={img}
                        alt="img"
                        className="rounded-t-lg m-2 w-[90%] h-[300px]" // Fixed size
                    />
                    <div className="p-4">
                        <h1 className="text-gray-600">
                            July 8, 2024 ♦ 6 min read
                        </h1>
                        <h2 className="text-[22px] font-bold mt-2">
                            Minimum Lovable Product: From Planning to Execution
                        </h2>
                        <span className="pt-4 text-red-600 font-semibold flex items-center">
                            Read the article <IoMdArrowRoundForward className="ml-2" />
                        </span>
                    </div>
                </div>

                {/* Second Column */}
                <div className="flex flex-col">
                    <img
                        src={img}
                        alt="img"
                        className="rounded-t-lg m-2 w-[90%] h-[300px]" // Fixed size
                    />
                    <div className="p-4">
                        <h1 className="text-gray-600">
                            July 8, 2024 ♦ 6 min read
                        </h1>
                        <h2 className="text-[22px] font-bold mt-2">
                            Minimum Lovable Product: From Planning to Execution
                        </h2>
                        <span className="pt-4 text-red-600 font-semibold flex items-center">
                            Read the article <IoMdArrowRoundForward className="ml-2" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog;
