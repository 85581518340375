import React, { useRef, useState } from 'react'
import logo from '../assets/logoAs.svg'
import facebook from '../assets/Group 50.svg'
import insta from '../assets/Group 51.svg'
import youtube from '../assets/Group 52.svg'
import twitter from '../assets/Group 53.svg'
import { IoCallOutline } from "react-icons/io5";
import { TbMail } from "react-icons/tb";
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import { TbBrandLinkedin } from "react-icons/tb";


const Footer = () => {
  const form = useRef();
  const email = useRef()

  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
      e.preventDefault();

      emailjs
          .sendForm('service_2ezkg0z', 'template_n9u995g', form.current, {
              publicKey: 'LTJkxZgL4pt3EGWm4',
          })
          .then(
              () => {
                  console.log('SUCCESS!');
                  setSubmitted(true);
              },
              (error) => {
                  console.log('FAILED...', error.text);
              },
          );
  };

  const scrollToSection = (event) => {
    event.preventDefault();
    const section = document.getElementById('features');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollIntoViewSection =(event) =>{
    event.preventDefault();
    const section = document.getElementById('blogs');
    section.scrollIntoView({behavior: 'smooth'})

  }


  return (
    <div className=" bg-[#111538] h-auto md:h-[30vh] p-12">
      <div className="flex flex-col md:flex-row  gap-8 md:gap-20 xl:ps-24 w-full ">
        <div className=" flex flex-col sm:space-y-24">
          <img src={logo} alt='logo' className='w-72'/>
          <div className='flex gap-4 p-2'>
           <img src={facebook} alt='facebook'  />
           <Link to='hhttps://www.instagram.com/colabrary'><img src={insta} alt='insta'  /></Link>
           <Link to='https://www.linkedin.com/company/colabrary'><TbBrandLinkedin size={25} className='text-white'/></Link>
           <img src={twitter} alt='twitter'  />
          </div>
        </div>
        <div className="text-[#FFFFFF]">
          <ul className="space-y-2">
            <li className='fo-menu-head'>About</li>
            {/* <li className='fo-submenu'>Menu</li> */}
            <li> <Link className='fo-submenu cursor-pointer' to="features" onClick={scrollToSection} >
          Features
        </Link></li>
            <li>
            <Link className='fo-submenu cursor-pointer' to="blogs">News & Blogs</Link></li>
            {/* <li className='fo-submenu'>Help & Supports</li> */}
          </ul>
        </div>
        <div className="text-[#FFFFFF]">
          <ul className="space-y-2">
            <li className='fo-menu-head'>Company</li>
            {/* <li className='fo-submenu'>How to work</li> */}
            <li>
              <Link className='fo-submenu' to='https://officielcrm.com/' target='_blank' rel='noopener noreferrer'>
              Terms and Conditions </Link></li>
            
            {/* <li className='fo-submenu'>Pricing</li>
            <li className='fo-submenu'>FAQ</li> */}
          </ul>
        </div>
        <div className="text-[#FFFFFF]">
          <ul className="space-y-2">
            <li className='fo-menu-head'>Contact Us</li>
            <li className='flex gap-1'><IoCallOutline  className='mt-1'/><span>+91 920 21 42423</span></li>
            <li className='flex gap-1'><TbMail size={18} className='mt-[5px]'/><span>info@colabrary.com</span></li>
          </ul>
        </div>

                        <div className='flex space-x-4 justify-center md:justify-start'>
                          
                            {!submitted ? (
                                <form ref={form} onSubmit={sendEmail} className='space-x-2 space-y-2'>
                                  <h1 className='sm:text-lg text-[15px] text-[#808080] Poppins-light pl-2'>Register Here </h1>
                                  <div className=' flex gap-1'>
                                    <input
                                        ref={email} 
                                        type='email'
                                        name='from_email'
                                        placeholder='Enter Email'
                                        className='p-[5px] bg-transparent rounded-lg outline-none border border-[#E3904194]'
                                    />
                                    <button type='submit' className='btn-signlog py-1 bg-[#E3904194] Poppins-bold'>
                                        Subscribe
                                    </button>
                                    </div>
                                </form>
                            ) : (
                                <p className='text-green-500'>We received your message and will contact you back soon.</p>
                            )}
                        </div>
        
      </div>
    </div>
  )
}

export default Footer
