import React from 'react'
import img from '../../assets/Rectangle444.png'

const BlogDetails = () => {
    return (
        <div className='w-full h-72'>
            <div className='bg-gray-100 p-6'>
                <h1 className='text-[63px] Poppins-bold px-10 pt-10 leading-[58px] '>User Retention: From Measuring to Improving</h1>
                <div className='md:flex justify-between px-10 pt-4'>
                    <div className='md:flex gap-2'>
                        <button className=' bg-slate-200 hover:bg-slate-300 p-1 rounded-lg px-3 focus:outline-none'>Metrics</button>
                        <button className=' bg-slate-200 hover:bg-slate-300 p-1 rounded-lg px-3 focus:outline-none'>SaaS</button>
                        <button className=' bg-slate-200 hover:bg-slate-300 p-1 rounded-lg px-3 focus:outline-none'>
                            User Interviews</button>
                        <button className=' bg-slate-200 hover:bg-slate-300 p-1 rounded-lg px-3 focus:outline-none'>UX Strategy</button>
                    </div>
                    <div>
                        <span className='Poppins-light text-sm'>July 8, 2024 ♦
                            8 Min read</span>
                    </div>
                </div>
            </div>
            <div className="flex w-full pt-4 px-16 relative">
    {/* Left Section */}
    <div className="w-[30%] sapce-y-8 sticky top-0">
        <h1 className="font-bold text-lg mb-2">Content:</h1>
        <p>What's retention & who needs to measure it?</p>
        <p>Measuring retention</p>
        <p>How do you know your retention is good?</p>
    </div>

    {/* Right Section */}
    <div className="w-[80%] overflow-y-auto h-[400px] scrollbar-hide">
        <img src={img} alt="Content image" className="rounded-lg mb-4 w-[80%]" />
        <p className="text-gray-700 w-[90%]">
            Acquiring a new customer can cost five times more than retaining an existing one. Additionally, increasing customer retention just by 5% can increase profits from 25-95%. In other words, understanding the nuances of customer retention is paramount.
            You can’t get away with just attracting new customers. Your strategic goal is to keep them engaged and loyal. In this article, we will go through the techniques we employ to measure and improve retention for our clients. Let’s dive right in.
        </p>

    </div>
</div>

        </div>
    )
}

export default BlogDetails