import React from 'react';
import rectengle44 from '../../assets/Rectangle444.png'
import { LuUser2 } from 'react-icons/lu'
import rectengle41 from '../../assets/Rectangle 2041.png'


function ScrolllBar() {
  const buttonDetails = [
    {
      label: 'Feeds',
      description: 'Stay updated with the latest news and updates tailored to your interests. Feeds help you stay in the loop with real-time information.',
    },
    {
      label: 'Calendar',
      description: 'Manage your schedule with our integrated calendar. Keep track of important dates, events, and deadlines all in one place.',
    },
    {
      label: 'Dost (Communication Module)',
      description: 'Connect with peers and colleagues effortlessly using Dost. This module facilitates seamless communication within the platform.',
    },
    {
      label: 'Whiteboard',
      description: 'Collaborate in real-time with the Whiteboard feature. Perfect for brainstorming sessions and visualizing ideas together.',
    },
    {
      label: 'Task Management',
      description: 'Organize and track your tasks efficiently. Task Management helps you stay on top of your responsibilities and meet deadlines.',
    },
    {
      label: 'Articles',
      description: 'Access a curated selection of articles. Expand your knowledge on various topics and stay informed with the latest insights.',
    },
    {
      label: 'AI Search',
      description: 'Leverage the power of AI to find exactly what you need. AI Search enhances your ability to quickly locate information within the platform.',
    },
    {
      label: 'YU (Video Learning Platform)',
      description: 'Enhance your learning experience with YU, our video learning platform. Access a wide range of educational content at your fingertips.',
    },
  ];

  const button1 = [
    {
      label: 'Reels',
      description: 'Engage with short, impactful videos that capture key moments and insights. Perfect for quick updates and highlights.',
    },
    {
      label: 'MS Office Integration',
      description: 'Seamlessly integrate with Microsoft Office tools like Word, Excel, and PowerPoint. Enhance your productivity by working within familiar environments.',
    },
    {
      label: 'AI-powered Tools',
      description: 'Utilize advanced AI tools to automate tasks, generate insights, and enhance decision-making. Boost efficiency and innovation with AI assistance.',
    },
    {
      label: 'Explore more',
      description: 'Explore a wide range of additional features designed to enhance your experience. Stay tuned for more updates and innovations.',
    },
  ];

  return (
    <>
    <div className='container1 lg:px-36  xs:px-2 md:flex justify-center md:justify-between'>
      <div className='left stiky-bx'>
      <div className='flex justify-center'>
        <img src={rectengle44} alt="rectengle41" className=''/>
        </div>
        <div className="stiky-bx-1">
          <h1 className="stiky-bx-h1">
          October 2024
              <br />
              Launch
          </h1>
          <button className="bg-[#FFCF59] text-sm Poppins-bold rounded-md p-1 px-2">
            READ MORE
          </button>
        </div>
      </div>
      
      <div className='right scroll-btn-div '>
        {buttonDetails.map((detail, index) => (
          <div key={index} className="scroll-card">
             <div className="scroll-card-icon bg-[#0F0741] text-white p-2 rounded-full" style={{background:'#0F0741'}}>
                <LuUser2 size={24} />
              </div>
            <h2>{detail.label}</h2>
            <p>{detail.description}</p>
          </div>
        ))}
      </div>
    </div>
    <div className='container1 lg:px-36 xs:px-2  md:flex justify-center md:justify-between'>
      <div className='left stiky-bx mt-4'>
        <div className='flex justify-center'>
      <img src={rectengle41} alt="rectengle41"/>
      </div>
        <div className="stiky-bx-1">
          <h1 className="stiky-bx-h1">
            Future
            <br />
            Enhancements
          </h1>
          <button className="bg-[#FFCF59] text-sm Poppins-bold rounded-md p-1 px-2">
            READ MORE
          </button>
        </div>
      </div>
      <div className='right scroll-btn-div md:my-0 xs:my-10'>
        {button1.map((detail, index) => (
          <div key={index} className="scroll-card">
             <div className="scroll-card-icon bg-[#0F0741] text-white p-2 rounded-full" style={{background:'#0F0741'}}>
                <LuUser2 size={24} />
              </div>
            <h2>{detail.label}</h2>
            <p>{detail.description}</p>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default ScrolllBar;
