import React, { useEffect, useRef, useState } from 'react'
import homeimage from '../assets/Illustration 2 1.svg'
import flower from '../assets/flower.svg'
import flower1 from '../assets/Vector (1).svg'
import rectengle from '../assets/Rectangle 2037.svg'
import ellipse from '../assets/Ellipse 1.svg'
import ellipse1 from '../assets/Ellipse 2.svg'
import { Link } from 'react-router-dom'
import PopUp from '../components/PopUp'


const Home = () => {
    const [isOpen , setIsOpen] = useState(false)

    const handlePopModel = () =>{
        setIsOpen(!isOpen)
    }

    useEffect(()=>{
            setIsOpen(true)
    },[])

    const onClosePop = () =>{
          setIsOpen(false)
    }

    return (<>
        {/* <img src={flower} alt='flower'/> */}
        <div className='w-full '>
            <div className='flex flex-col lg:flex-row items-center md:justify-between'>
                {/* Text Section */}
                <div className='lg:w-[50%] flex'>
                    <img src={flower} alt='flower' className='res-hidden' />
                    <div className='md:ps-24 md:p-8 xs:p-4 md:text-left xs:text-center'>
                        <h1 className='md:text-4xl Poppins-bold text-2xl md:mb-2'>Reimagine Learning</h1>
                        <h2 className='md:text-2xl text-xl Poppins-bold mb-2 text-[#2989CA]'>Connect, Create, Conquer</h2>
                        <p className='sm:text-lg text-[15px] text-[#808080] Poppins-light mb-6'>
                            In today’s fast-paced digital world, education needs to be more engaging, collaborative, and accessible. Colabrary is a cutting-edge social learning platform designed to bring students, educators, and institutions together in a unified space. Whether you’re a school student, a college aspirant, or part of a coaching institute, Colabrary offers the tools and community you need to succeed.
                        </p>
                        <h1 className='Poppins-bold text-lg'>Become a creator with YU on Colabrary and inspire young minds! Click here to get started</h1>
                        <Link to='https://creator.colabrary.com/' type='submit' className='btn-signlog py-1 mt-2 bg-[#E3904194] Poppins-bold'>
                            Become YU Creator
                        </Link>
                    </div>
                </div>
                {/* Image Section */}
                <div className='w-[50%] flex justify-center gap-4'>
                    <img src={flower1} alt='flower' className=' pb-6 w-14 h-24 self-end ' />
                    <img className='w-full md:w-[55%]' src={homeimage} alt='Reimagine Learning' />
                </div>
            </div>
            <div className='xl:flex xs:hidden justify-center text-black  items-center py-6 gap-4 lg:absolute xl:bottom-[43px] 2xl:bottom-[0px] left-[620px] z-50'>
                <span className='span1'>
                    250
                    <br />
                    <span className='span2'>Total Instructor</span>
                </span>
                {/* Vertical Line */}
                <div className="vertical-line"></div>
                <span className='span1'>
                    100+
                    <br />
                    <span className='span2'>Collaboration</span>
                </span>
            </div>
            <div className="relative w-full res-hidden-xl">
                <img src={ellipse} alt="ellipse" className="left-[-40px] md:auto h-24 w-20 top-[174px] absolute z-20" />
                <img src={ellipse1} alt="ellipse1" className="right-0 md:w-auto w-8 h-24 lgt:top-[0px] top-[140px] absolute z-20" />
                <img src={rectengle} alt="rectengle" className="w-full bottom-[-176px]  absolute z-10" />
            </div>
        </div>
        <PopUp OpenPOP={isOpen} onClose={onClosePop}/>
    </>)
}

export default Home
