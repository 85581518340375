import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const PopUp = ({ OpenPOP, onClose }) => {
    const navigate = useNavigate()
    if (!OpenPOP) return null; // Conditionally render the popup only if OpenPOP is true
       
    return (
        <div className='fixed w-full h-screen inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50'>
            <div className='bg-white p-6 rounded-xl shadow-lg max-w-2xl  w-full relative'>
                <button
                    onClick={onClose}
                    className='absolute top-3 right-3 text-gray-500 hover:text-gray-800 text-xl font-bold'
                >
                    &times;
                </button>
                <div className='text-center'>
                    <h2 className='text-2xl font-semibold mb-2 text-[#2989CA]'>
                        🌟 Become a Creator on YU! 🎥
                    </h2>
                    <p className='text-gray-600 mb-6'>
                        Ready to share your knowledge and creativity?
                        Join our community of passionate creators and inspire others with your unique content.

                        Sign up now and start your journey to impact learning!
                    </p>
                    <Link to="https://creator.colabrary.com/"
                        className='bg-[#2989CA] text-white py-2 px-6 rounded-md shadow hover:bg-[#257ab3] transition duration-300'
                    >
                        Register as a creator
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PopUp;
