import React from 'react'
import NavBar from './components/NavBar'
import Home from './pages/Home'
import Features from './pages/Features'
import Misson from './pages/mission/Misson'
import Footer from './components/Footer'
import Blog from './pages/blog/Blog'
import { Route, Routes } from 'react-router-dom'
import BlogDetails from './pages/blog/BlogDetails'
import ScrolllBar from './pages/mission/ScrolllBar'

const App = () => {
  return (
    <div className=''>
      <NavBar />
      <Routes>
        <Route path='/' element={
          <>
      <Home />
      <div id='features' className=' xl:mt-[200px] mb-auto'>
      <Features />
      </div>
      <div className='mt-[30px] mb-auto'>
      <Misson />
      </div>
      </>} />
      <Route path='/blogs' element={<Blog />} id='blogs' />
      <Route path='/blog-details' element={<BlogDetails />} />
      </Routes>
      <div className=''>
      <Footer />
      </div>
    </div>
  )
}

export default App













   {/* <Routes>
        <Route path='/blogs' element={<Blog />}/>
        <Route path='/blog-details' element={<BlogDetails />}/>
      </Routes> */}