import React from 'react'
import group from '../assets/Group.svg'
import rectengle from '../assets/Rectangle 2037.svg'
import fram from '../assets/Frame (1).svg'
import fram1 from '../assets/Frame (2).svg'
import fram2 from '../assets/Frame (3).svg'
import fram3 from '../assets/Frame.svg'
import grp from '../assets/grp.svg'


const Features = () => {
    const features = [
        {
            img: group,
            heading: "Social Learning Network",
            content: "Connect with peers, educators, & institutions in a collaborative online learning environment that enhances engagement & knowledge sharing."
        },
        {
            img: fram3,
            heading: "Virtual Classroom",
            content: "Access live classes, recorded lectures, and interactive content that makes learning more effective and enjoyable. "
        },
        {
            img: grp,
            heading: "Content Marketplace",
            content: "Discover and share valuable study materials, notes, and educational resources within the Colabrary community."
        }
        ,
        {
            img: fram,
            heading: "Interactive Q&A Forum",
            content: "Engage in discussions, ask questions, and exchange knowledge with fellow students and educators in a dedicated forum. "
        },
        {
            img: fram1,
            heading: "Creative Expression Tools",
            content: "Utilize creative formats like memes and videos to express your understanding and make learning fun and memorable. "
        },
        {
            img: fram2,
            heading: "Video Sharing Platform",
            content: "Share educational videos tailored to your needs, with options for public or private viewing, enhancing the learning experience. "
        }
    ];
    return (
        <div className=' flex flex-col justify-center items-center'>
            <div>
                <h1 className='text-[#258bd6] md:text-[52px] text-[30px] Poppins-bold text-center'>Key <span className='text-[#1C1C1C] Poppins-meadiam'>features</span></h1>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  gap-2 mt-8'>
                    {features.map((feature, index) => (
                        <div key={index} className='featurebox flex-wrap'>
                            <div className='imgdiv '>
                                <img src={feature.img} alt={feature.heading} className='img-size' />
                            </div>
                            <h1 className='featurebox-heading'>{feature.heading}</h1>
                            <p className='featurebox-content Poppins-light'>
                                {feature.content}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Features